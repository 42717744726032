<template>
  <b-container>
    <b-container
      v-if="paymentMethodOptions"
      class="select-payable"
      fluid
    >
      <b-form>
        <div class="container select-payable-table-container">
          <b-form-group>
            <header class="table-header-first">Credit/Debit Cards</header>
            <b-table
              :fields="cardFields"
              :items="cards"
              :sticky-header="true"
              show-empty
              responsive
              striped
            >
              <template v-slot:head(select) />
              <template v-slot:cell(select)="data">
                <b-form-radio v-model="payableId" name="payableId" :value="data.item.id" />
              </template>
              <template v-slot:cell(card)="data">
                <b-img :src="cardLogo(data.item.brand)" class="mr-2" />
                {{ `**** **** **** ${data.item.last4}` }}
              </template>
              <template v-slot:cell(name)="data">
                {{ `${startCase(data.item.firstName)} ${startCase(data.item.lastName)}` }}
              </template>
            </b-table>
            <b-table
              :fields="achFields"
              :items="automatedClearingHouses"
              :sticky-header="true"
              show-empty
              responsive
              striped
            >
              <template v-slot:head(select) />
              <template v-slot:cell(select)="data">
                <b-form-radio v-model="payableId" name="payableId" :value="data.item.id" />
              </template>
              <template v-slot:cell(accountNumber)="data">
                <b-img :src="`/images/automatedClearingHouses/ach.svg`" class="mr-2" />
                {{ maskAccountNumber(data.item.bankAccountNumber) }}
              </template>
              <template v-slot:cell(accountNickname)="data">
                {{ data.item.nickname }}
              </template>
            </b-table>
            <b-row>
              <b-col>
                <b-button
                  variant="link"
                  class="mt-2 mb-2 pl-0 ml-0"
                  aria-label="add payment method button"
                  @click="presentAddPayment"
                >
                  <i><feather-icon type="plus" /></i>
                  Add Payment Method
                </b-button>
              </b-col>
            </b-row>
            <b-row v-if="tosUrl && tosUrl !== ''">
              <b-col>
                <b-form-group>
                  <span class="mt-4 mb-2">
                    I accept the
                    <a :href="tosUrl" target="_blank">
                      Terms and Conditions
                    </a>
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form-group>
        </div>
      </b-form>
    </b-container>
    <payment-method-modal
      @badCardIssuer="setBadCardIssuer"
      @update="addPaymentMethodDidUpdate"
    />
  </b-container>
</template>

<script>
import { paymentMethodsMixin } from '@/mixins/paymentMethodsMixin'
import { mapGetters } from 'vuex'
import { dateLabel } from '@/directives/directives'
import { formatDateString } from '@/common/modules/formatters'
import PaymentMethodModal from '@/components/PaymentMethodModal'
import FeatherIcon from '@/components/shared/FeatherIcon'
import _ from 'lodash'
import { getCardLogo } from '@/common/modules/cc'

export default {
  name: 'SelectPayable',
  components: { FeatherIcon, PaymentMethodModal },
  directives: {
    dateLabel: dateLabel,
  },
  mixins: [paymentMethodsMixin],
  props: {
    selection: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      token: null,
      paymentMethodOptions: null,
      loaded: false,
      select: null,
      selectAll: false,
      rowSelection: {},
      tosUrl: null,
      cardFields: ['select', 'card', 'name'],
      achFields: ['select', 'accountNumber', 'accountNickname'],
      payableId: null,
    }
  },
  computed: {
    ...mapGetters({
      companies: 'companies/getAll',
    }),
    valid() {
      return this.paymentForm.payableId !== null
    },
  },
  watch: {
    payableId() {
      this.$emit('payableInfo', this.payableId)
    },
  },
  async mounted() {
    await this.retryPaymentMethods()
    this.tosUrl = this.$store.getters['website/newTosUrl']
    this.loaded = true
  },
  methods: {
    presentAddPayment() {
      this.$bvModal.show('payment-method-modal')
    },
    maskAccountNumber(accountNumber) {
      return accountNumber.replace(/X/g, '*')
    },
    async addPaymentMethodDidUpdate(addedPaymentMethodId, errors) {
      this.$bvModal.hide('payment-method-modal')
      this.displayResultToast(addedPaymentMethodId, false, errors)
    },
    cardLogo(brand) {
      return getCardLogo(brand)
    },
    contains(id) {
      return this.selection.filter(item => item.id === id).length > 0
    },
    rowSelected(item) {
      if (this.contains(item.id)) {
        this.selection = this.selection.filter(record => record.id !== item.id)
      } else {
        this.selection.push(item)
      }
      this.rowSelection[item.id] = this.contains(item.id)
    },
    startCase(input) {
      return _.startCase(input)
    },
    formatDate: formatDateString,
  },
}
</script>

<style scoped lang="scss">

  .select-payable {

    .select-payable-table-container {

      .table-header {
        color: #363636;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 150% */
        margin-top: 42px;
        margin-bottom: 8px;

        &-first {
          @extend .table-header;
          margin-top: 12px;
        }
      }
    }

    // custom striped styling
    ::v-deep(.table-striped) {

      tbody tr:nth-of-type(odd) {
        background: #FFF;
      }

      tbody tr:nth-of-type(even) {
        background: #F5F6F9;
      }
    }

    ::v-deep(.b-table) {
      border-spacing: 0px;
      border-collapse: separate;
      max-height: 300px;
      color: #363636;
      margin-bottom: 0px;

      th {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px; /* 214.286% */
        border-top: 1px solid #D0D0D0 !important;
        border-bottom: 1px solid #D0D0D0 !important;
        background: #F5F6F9 !important;
      }

      td {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 187.5% */
        border: none !important;
      }

      // custom column adjustments to align each
      th[aria-colindex="1"] {
        width: 15% !important;
      }

      th[aria-colindex="2"] {
        width: 42.5% !important;
      }

      th[aria-colindex="3"] {
        width: 42.5% !important;
      }
    }
  }

</style>
