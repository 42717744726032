<template>
  <div>
    <b-modal id="cancellation-reasons" hide-footer
             title="Reason for cancellation"
             :size="size"
    >
      <b-alert
        v-if="selectedBizIdServices?.length"
        class="d-flex align-items-center"
        variant="danger"
        show
      >
        <b-icon
          icon="exclamation-triangle-fill"
          variant="warning"
          class="mr-2"
        />
        <div v-if="!selectedHostingTypes && sslSelected">
          Any website or email service associated with a cancelled SSL service will <strong>lose their secure encryption</strong>.
        </div>
        <div v-else>
          <strong>All {{ selectedHostingTypes }} data will be deleted</strong> from our database after 45 days.
          Reactivate service from Websites/Email tab at any time.
        </div>
      </b-alert>
      <b-form @submit="cancellationFeedback">
        <b-form-group>
          <label class="label-sm">Why are you canceling your services with us today?</label>
          <b-form-select v-model="feedBack" class="form-control" required>
            <option v-for="option in cancellationOptions" :key="option.cancellation_reason" :value="option.id">
              {{ option.cancellation_reason }}
            </option>
          </b-form-select>
          <div v-if="isOtherOption">
            <label class="label-sm mt-3">Please explain in 100 characters or less</label>
            <b-form-textarea
              id="textarea"
              v-model="feedBackIfOther"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
              required
              maxlength="100"
            />
          </div>
          <div v-if="servicesIncludesVirtualOffice">
            <label class="label-sm mt-3 text-danger">Warning: Cancelling a Virtual Office service will cancel associated Virtual Phone services.</label>
          </div>
        </b-form-group>
        <b-button
          class="mt-4 submit-btn-width"
          variant="danger"
          :disabled="!feedBack"
          type="submit"
        >
          Submit
        </b-button>
      </b-form>
    </b-modal>
    <b-modal id="cancel-services" hide-footer
             title="Service Cancellation Agreement"
             :size="size"
    >
      <div v-if="outstandingBalance > 0">
        <p>
          You are cancelling your subscription. We charge by the day and your pro-rated amount is:
        </p>

        <p>Balance Due: {{ outstandingBalance }}</p>
        <select-payable :invoice-view="false" @payableInfo="payableInfo" />
      </div>
      <div v-if="activeFilings.length > 0">
        <p>
          <strong>Active Filings</strong>
          <br>
          The following filings are affected by cancelling these services. Select ones you would like to cancel.
        </p>
        <ct-table
          ref="activeFilingsTable"
          :items="activeFilings"
          :fields="filingFields"
          :loaded="filingsLoaded"
          :selectable-function="selectableFunction"
          :selectable="true"
          select-mode="multi"
          :is-paginated="false"
          @selection-changed="selectionEvent"
        />
      </div>
      <service-cancellation-text :services="selectedServices" />
      <strong>Representative</strong>
      <p class="m-b-lg">
        <i>
          By typing your name, you are agreeing to the end of service agreement. You are agreeing this
          is the equivalent of a traditional signature. This will cancel the selected services with
          us.
        </i>
      </p>
      <b-form-group>
        <label class="label-lg">* Enter your name to agree</label>
        <b-form-input v-model="signature" type="text" class="form-control" required />
      </b-form-group>
      <div class="d-flex justify-content-center">
        <b-button
          class="mt-4 submit-btn-width"
          variant="danger"
          :disabled="submitBtnDisabled || loading"
          @click="cancelServices"
        >
          <b-spinner v-if="loading" small />
          <div v-else>
            <div v-if="selection.length > 0">
              I agree, cancel my services and filings
            </div>
            <div v-else>
              I agree, cancel my services
            </div>
          </div>
        </b-button>
        <b-button
          v-if="outstandingBalance > 0"
          class="mt-4 submit-btn-width ml-3"
          variant="dark"
          :disabled="withoutPayingBtnDisabled || loading"
          @click="cancelServicesWithoutPaying"
        >
          <b-spinner v-if="loading" small />
          <div v-else>
            I agree, cancel without paying
          </div>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import SelectPayable from '@/components/SelectPayable.vue'
import { mapActions, mapGetters } from 'vuex'
import CtTable from '@/components/shared/CtTable'
import ServiceCancellationText from '@/components/cancellation/ServiceCancellationText'
import http from '../../http.js'

  export default {
    name: 'CancellationModal',
    components: { SelectPayable, ServiceCancellationText, CtTable },
    props: {
      bus: null,
    },
    data() {
      return {
        signature: null,
        feedBack: false,
        feedBackIfOther: null,
        payable: null,
        services: null,
        selectedServiceTypes: [],
        outstandingBalance: null,
        loading: false,
        raServices: [],
        compServices: [],
        selection: [],
        selectedServices: [],
        size: 'xl',
        filingFields: [
          { key: 'company.name', label: 'Company', sortable: true, sortAs: 'company.name' },
          { key: 'name', label: 'Filing Type', sortable: true },
          { key: 'registration.jurisdiction.state_province_region', label: 'Jurisdiction', sortable: true, sortAs: 'registration.jurisdiction.state_province_region' },
          { key: 'status', label: 'Status', sortable: true },
        ],
      }
    },
    computed: {
      ...mapGetters('orderItems', ['activeFilings', 'filingsLoaded']),
      ...mapGetters('cancellationReasons', ['allOptions', 'allOptionsGeneric', 'allOptionsForServiceType', 'otherOptions']),
      cancellationOptions() {
        if (this.selectedServiceTypes.length === 1 && this.selectedServiceTypes[0] === 'virtual-phone') {
          return this.allOptionsForServiceType(this.selectedServiceTypes[0])
        } else if(this.selectedServiceTypes.length > 1 && this.selectedServiceTypes.includes('virtual-phone')) {
          return this.allOptions
        } else {
          return this.allOptionsGeneric
        }
      },
      submitBtnDisabled() {
        return this.invalidSignature() || this.noPayableForPayment()
      },
      withoutPayingBtnDisabled() {
        return this.invalidSignature()
      },
      isOtherOption() {
        // For some reason the feedBack variable is used as a boolean and an otherOption id
        if (this.feedBack) {
          return this.otherOptions.filter(o => o.id === this.feedBack).length > 0
        } else {
          return false
        }
      },
      servicesIncludesVirtualOffice() {
        return this.services?.some(service => service.product?.name?.includes('Virtual Office'))
      },
      selectedBizIdServices() {
        const bizIdTypes = ["business-website-hosting", "business-email", "domain-ssl"]
        return this.services?.filter(service => bizIdTypes.includes(service.type))
      },
      selectedBizIdServiceTypes() {
        return this.selectedBizIdServices.map(service => service.type)
      },
      sslSelected() {
        return this.selectedBizIdServiceTypes.includes("domain-ssl")
      },
      webSelected() {
        return this.selectedBizIdServiceTypes.includes("business-website-hosting")
      },
      emailSelected() {
        return this.selectedBizIdServiceTypes.includes("business-email")
      },
      selectedHostingTypes() {
        if (this.webSelected && this.emailSelected) {
          return "website and email"
        } else if (this.webSelected) {
          return "website"
        } else if (this.emailSelected) {
          return "email"
        } else {
          return null
        }
      },
    },
    mounted() {
      this.getAllOptions()
      this.$root.$on('bv::modal::hidden', () => {
        this.$emit('modal-change', false)
      })
      this.bus.$on('showCancelServices', (services) => {
        this.services = services
        this.openCancellationReasons()
      })
    },
    methods: {
      ...mapActions('orderItems', ['loadJurisdictionActiveFilings', 'loadComplianceActiveFilings', 'cancelOrderItems']),
      ...mapActions('cancellationReasons', ['getAllOptions']),
      openCancellationReasons() {
        this.feedBack = false
        this.feedBackIfOther = null
        this.selectedServiceTypes = [...new Set(this.services.map(s => s.type))]
        this.$bvModal.show('cancellation-reasons')
      },
      async cancellationFeedback(event) {
        event.preventDefault()
        this.$bvModal.hide('cancellation-reasons')
       await this.open()
      },
      async open() {
        this.selectedServices = this.services
        this.$bvModal.show('cancel-services')

        // Check all the selected services and pull out the ones with potential filings
        // that need to be canceled. This will be any filings for a company within the state the RA
        // service is being cancelled in, along with any filings generated within 90 days for compliance
        // services
        this.raServices = this.selectedServices.filter(s => s.type === 'registered-agent').map(s => s = { companyId: s.company_id, jurisdictionId: s.jurisdiction.id } )
        this.compServices = this.selectedServices.filter(s => s.type === 'compliance').map(s => s = s.id)
        this.loadActiveFilings()
        this.checkOutstandingBalance()
        // Clear out payable and signature in the case the user closes and the re-opens the modal
        this.payable = null
        this.signature = null
      },
      selectedServiceIds(services) {
        return services.map(a => a.id)
      },
      payableInfo(payableId) {
        this.payable = payableId
      },
      selectableFunction(filing) {
        return filing.status !== 'cancel-requested'
      },
      selectionEvent(selection) {
        this.selection = selection
      },
      invalidSignature() {
        return !this.signature || this.signature === ''
      },
      noPayableForPayment() {
        return this.payable === null && this.outstandingBalance > 0
      },
      async checkOutstandingBalance() {
        const result = await http.post('client/services/outstanding_balance_info', {
          services: { service_ids: this.selectedServiceIds(this.selectedServices) },
        })
        this.outstandingBalance = result.data.result.balance.toFixed(2)
      },
      async cancelServices() {
        this.loading = true
        let cancelResponse = ''
        const uniqueCompanies = [...new Set(this.selectedServices.map(item => item.company_id))]
        const outstandingBalanceExists = this.outstandingBalance > 0

        for (const company_id of uniqueCompanies) {
          const companyServices = this.selectedServices.filter(x => x.company_id === company_id)
          if (outstandingBalanceExists) {
            cancelResponse = await this.payOutstandingBalanceAndCancel(
              companyServices,
              this.selection.filter(f=> f.company.id === company_id).map(f => f.id)
            ).catch(
              error => {
                let response = error.response
                response.type = 'Payment'
                return response
              }
            )
          } else {
            cancelResponse = await this.noOutstandingBalanceCancel(
              companyServices,
              this.selection.filter(f=> f.company.id === company_id).map(f => f.id)
            ).catch(error => {
              let response = error.response
              response.type = 'Cancellation'
              return response
            })
          }
        }

        for (const selectedService of this.selectedServices) {
          if (selectedService.product.category === "virtual-phone") {
            await this.$store.dispatch('virtualPhones/loadVirtualPhones', null, { root: true })
            break
          }
        }

        this.loading = false
        this.$emit('cancel-complete', cancelResponse)
        this.$bvModal.hide('cancel-services')
      },
      async cancelServicesWithoutPaying() {
        this.loading = true
        let cancelResponse = ''
        const uniqueCompanies = [...new Set(this.selectedServices.map(item => item.company_id))]

        for (const company_id of uniqueCompanies) {
          const companyServices = this.selectedServices.filter(x => x.company_id === company_id)
          cancelResponse = await this.noOutstandingBalanceCancel(companyServices, this.selection.map(f => f.id)).catch(error => {
            let response = error.response
            response.type = 'Cancellation'
            return response
          })
        }
        this.loading = false
        this.$emit('cancel-complete', cancelResponse)
        this.$bvModal.hide('cancel-services')
      },
      async loadActiveFilings() {
        await this.loadJurisdictionActiveFilings(this.raServices)
        await this.loadComplianceActiveFilings(this.compServices)
        this.$nextTick(() => {
          if (this.$refs.activeFilingsTable) {
            this.$refs.activeFilingsTable.selectAllRows()
          }
        })
      },
      async payOutstandingBalanceAndCancel(services, orderItemIds) {
        let data = await http.post('client/cancellations/pay_outstanding_balance_and_cancel_services_and_filings', {
          cancel: {
            token: this.payable,
            service_ids: this.selectedServiceIds(services),
            order_item_ids: orderItemIds,
            signature: this.signature,
            note: null,
            cancellation_reason_detail: this.feedBackIfOther,
            selected_cancellation_reason_id: this.feedBack,
          },
        })
        return data
      },
      async noOutstandingBalanceCancel(services, orderItemIds) {
        let data = await http.post('client/cancellations/cancel_services_and_filings', {
          cancel: {
            service_ids: this.selectedServiceIds(services),
            order_item_ids: orderItemIds,
            signature: this.signature,
            note: null,
            cancellation_reason_detail: this.feedBackIfOther,
            selected_cancellation_reason_id: this.feedBack,
          },
        })
        return data
      },
    },
  }
</script>

<style scoped lang="scss">
  .alert-danger {
    color: black;
    background-color: #ffa5001c;
    border-color: orange;
    padding: 0.5rem 0.75rem;
  }
</style>
