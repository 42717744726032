var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._m(1),
    _vm.registeredAgent
      ? _c("div", [_vm._m(2), _vm._m(3), _vm._m(4)])
      : _vm._e(),
    _vm.mailForwarding || _vm.callForwarding
      ? _c("div", [_vm._m(5), _vm._m(6), _vm._m(7), _vm._m(8)])
      : _vm._e(),
    _vm.compliance ? _c("div", [_vm._m(9), _vm._m(10)]) : _vm._e(),
    _vm.callForwarding || _vm.mailForwarding || _vm.registeredAgent
      ? _c("div", [_vm._m(11)])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("span", [
        _c("strong", [_vm._v("Representative agrees;")]),
        _vm._v(
          "\n      that this is binding on the company(s), and that if someone has access to this account,\n      whomever agrees to cancel, has the proper authority.\n    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("span", [
        _c("strong", [_vm._v("Representative agrees;")]),
        _vm._v(
          "\n      that this is a civil agreement and waives any and all rights or claims of statutory damages\n      or tort claims.\n    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("span", [
        _c("strong", [_vm._v("Representative agrees;")]),
        _vm._v(
          "\n        to release any and all liability or duty we had or that we continue to have to notify\n        Representative about lawsuits or any mail that we might continue to receive, even if our\n        office address is still listed on any part of the corporate filing or if we are still\n        listed as the registered agent.\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("span", [
        _c("strong", [_vm._v("Representative agrees;")]),
        _vm._v(
          "\n        that if we are still listed as the registered agent or our address is being listed, that\n        we have the right to remove ourselves and our address, which might put Representative in\n        default, and that your company might lose its license, good standing, or approval to\n        perform work by various agencies. We are not agreeing to perform any filings to remove\n        ourselves from your company, but we may choose to do so.\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("span", [
        _c("strong", [_vm._v("Representative agrees;")]),
        _vm._v(
          "\n        that as of today we will not forward service of process, legal notices, law suits, mail or\n        notices that we may continue to receive for Representative.\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [_vm._v("Representative agrees;")]),
      _vm._v(
        "\n      to release any and all liability or duty has regarding the immediate disconnection of an\n      attached call forwarding service and phone number.\n    "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [_vm._v("Representative agrees;")]),
      _vm._v(
        "\n      to release any and all liability or duty we had or continue to have to notify Representative\n      about regular mail received at Representative’s assigned suite number.\n    "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [_vm._v("Representative agrees;")]),
      _vm._v(
        "\n      that if the suite address is still in use and receiving unofficial mail, that we have the\n      right to return any such mail to the sender. We are not agreeing to work to remove ourselves\n      from a mailing list, but we may choose to do so.\n    "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [_vm._v("Representative agrees;")]),
      _vm._v(
        "\n      that as of today, we will not forward regular mail documents received at Representative’s\n      assigned suite number that we may continue to receive for Representative.\n    "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [_vm._v("Representative agrees;")]),
      _vm._v(
        "\n      to release any and all liability or duty we had or continue to have to complete any annual\n      or biennial compliance filings with the state.\n    "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [_vm._v("Representative agrees;")]),
      _vm._v(
        "\n      that as of today, the company has cancelled compliance service offered by us.\n    "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [_vm._v("Representative agrees;")]),
      _vm._v(
        "\n      to NOT file a “change of address” request with the United States Postal Service. This is a\n      specific address request form the USPS provides that will ultimately not work because our\n      address is not yours, but could make a mess for both of us. The address we provide clients\n      is our address.\n    "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }