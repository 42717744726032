<template>
  <div>
    <p>
      <span>
        <strong>Representative agrees;</strong>
        that this is binding on the company(s), and that if someone has access to this account,
        whomever agrees to cancel, has the proper authority.
      </span>
    </p>
    <p>
      <span>
        <strong>Representative agrees;</strong>
        that this is a civil agreement and waives any and all rights or claims of statutory damages
        or tort claims.
      </span>
    </p>
    <div v-if="registeredAgent">
      <p>
        <span>
          <strong>Representative agrees;</strong>
          to release any and all liability or duty we had or that we continue to have to notify
          Representative about lawsuits or any mail that we might continue to receive, even if our
          office address is still listed on any part of the corporate filing or if we are still
          listed as the registered agent.
        </span>
      </p>
      <p>
        <span>
          <strong>Representative agrees;</strong>
          that if we are still listed as the registered agent or our address is being listed, that
          we have the right to remove ourselves and our address, which might put Representative in
          default, and that your company might lose its license, good standing, or approval to
          perform work by various agencies. We are not agreeing to perform any filings to remove
          ourselves from your company, but we may choose to do so.
        </span>
      </p>
      <p>
        <span>
          <strong>Representative agrees;</strong>
          that as of today we will not forward service of process, legal notices, law suits, mail or
          notices that we may continue to receive for Representative.
        </span>
      </p>
    </div>
    <div v-if="mailForwarding || callForwarding">
      <p>
        <strong>Representative agrees;</strong>
        to release any and all liability or duty has regarding the immediate disconnection of an
        attached call forwarding service and phone number.
      </p>
      <p>
        <strong>Representative agrees;</strong>
        to release any and all liability or duty we had or continue to have to notify Representative
        about regular mail received at Representative’s assigned suite number.
      </p>
      <p>
        <strong>Representative agrees;</strong>
        that if the suite address is still in use and receiving unofficial mail, that we have the
        right to return any such mail to the sender. We are not agreeing to work to remove ourselves
        from a mailing list, but we may choose to do so.
      </p>
      <p>
        <strong>Representative agrees;</strong>
        that as of today, we will not forward regular mail documents received at Representative’s
        assigned suite number that we may continue to receive for Representative.
      </p>
    </div>
    <div v-if="compliance">
      <p>
        <strong>Representative agrees;</strong>
        to release any and all liability or duty we had or continue to have to complete any annual
        or biennial compliance filings with the state.
      </p>
      <p>
        <strong>Representative agrees;</strong>
        that as of today, the company has cancelled compliance service offered by us.
      </p>
    </div>
    <div v-if="callForwarding || mailForwarding || registeredAgent">
      <p>
        <strong>Representative agrees;</strong>
        to NOT file a “change of address” request with the United States Postal Service. This is a
        specific address request form the USPS provides that will ultimately not work because our
        address is not yours, but could make a mess for both of us. The address we provide clients
        is our address.
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ServiceCancellationText',
    props: {
      services: {
        type: Array,
        default: [],
      },
    },
    computed: {
      registeredAgent() {
        return this.services.some(s => s.type === 'registered-agent')
      },
      compliance() {
        return this.services.some(s => s.type === 'compliance')
      },
      mailForwarding() {
        return this.services.some(s => s.type === 'mail-forwarding')
      },
      callForwarding() {
        return this.services.some(s => s.type === 'call-forwarding')
      },
    },
  }
</script>

<style scoped>

</style>
